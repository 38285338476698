<template>
  <div>
    <section class="section is-main-section">
      <h1 class="title">
        {{ title }}
      </h1>
      <div class="columns">
        <div class="column is-2">
          <b-datepicker
              v-model="fromdate"
              placeholder="ຈາກວັນທີ"
              :locale="this.getDateLocale"
              icon="calendar-today"
              @input="selectFromDate"
          >
          </b-datepicker>
        </div>
        <div class="column is-2">
          <b-datepicker
              v-model="todate"
              placeholder="ຫາວັນທີ"
              :locale="this.getDateLocale"
              icon="calendar-today"
              @input="selectToDate"
          >
          </b-datepicker>
        </div>
        <div class="column is-3" v-if="user.role==='ADMIN'">
            <b-select placeholder="ສາຂາ" expanded v-model="branchId">
              <option :value="0">ທັງໝົດ</option>
              <option v-for="option in branchs"
                      :value="option.id"
                      :key="option.id">
                {{ option.name }}
              </option>
            </b-select>
          </div>

        <div class="column is-2">
          <button class="button" @click="get">
            <b-icon icon="magnify" />
          </button>
        </div>
        <div class="column is-2 has-text-left" v-if="models.length>0">
          <b-button
              @click="exportExcel"
              size="is-medium">
            <b-icon
                icon="file-excel-outline"
                style="margin-right: 0.5em"></b-icon>
            ດາວໂຫລດເປັນ excel
          </b-button>
        </div>
      </div>
      <card-component class="has-table has-mobile-sort-spaced">
        <div class="columns" style="padding: 1em 1em 0em 1em">
          <div class="column is-6 title has-text-left">
            {{ models.length }} ລາຍການ 
            , ຄ່າຝາກ [{{ _.sumBy(models ,'amount') | formatnumber }} ກີບ , {{ _.sumBy(models,'amount_thb') | formatnumber }} ບາດ ]
          </div>
          <div class="column is-6 title has-text-left">
            ຄ່າເຄື່ອງ
            [₭ {{ _.sumBy(models,'cod_lak' ) | formatnumber }}
           , ฿ {{ _.sumBy(models,'cod_thb') | formatnumber }}]
            , ຮັບເງີນ
            [₭ {{ _.sumBy(models,'cod_payment_lak') | formatnumber }}
           , ฿ {{ _.sumBy(models,'cod_payment_thb') | formatnumber }}]
          </div>
        </div>

        <hr />
        <b-table
            :loading="isLoading"
            :paginated="perPage < models.length"
            :per-page="perPage"
            :striped="true"
            :hoverable="true"
            default-sort="created_date"
            :data="models"
            :current-page.sync="currentPage"
            :pagination-rounded="true"
            :narrowed="true" class="is-narrow is-size-7"
        >
          <b-table-column label="#" v-slot="props">
            {{ props.index+(currentPage>1?(perPage*currentPage-perPage):0)+1 }}
          </b-table-column>
          <b-table-column
              label="ວັນທີຈ່າຍເຄື່ອງ"
              field="delivery_date"
              sortable
              v-slot="props"
          >
            {{ props.row.delivery_date | datetime }}
          </b-table-column>
          <b-table-column label="ລະຫັດ" field="code" sortable v-slot="props">
            {{ props.row.code }}
          </b-table-column>
          <b-table-column
              label="ຜູ້ສົ່ງ"
              field="sendername"
              sortable
              v-slot="props"
          >
            {{ props.row.sendername }}
          </b-table-column>
          <b-table-column
              label="ຜູ້ຮັບ"
              field="receivername"
              sortable
              v-slot="props"
          >
            {{ props.row.receivername }}
          </b-table-column>
          <b-table-column
              label="ສາຂາຕົ້ນທາງ"
              field="branch_branchToshipment_from_branch_id.code"
              sortable
              v-slot="props"
          >
            {{ props.row.branch_branchToshipment_from_branch_id.code }}
          </b-table-column>
          <b-table-column
              label="ສາຂາປາຍທາງ"
              field="branch_branchToshipment_to_branch_id.code"
              sortable
              v-slot="props"
          >
            {{ props.row.branch_branchToshipment_to_branch_id.code }}
          </b-table-column>
          <b-table-column
              label="ຄ່າຝາກ"
              field="amount"
              sortable
              v-slot="props"
              cell-class="has-text-right"
          >
            {{ props.row.amount | formatnumber }}
          </b-table-column>
          <b-table-column
              label="COD ກີບ"
              field="amount"
              sortable
              v-slot="props"
              cell-class="has-text-right"
          >
            {{ props.row.cod_lak | formatnumber }}
          </b-table-column>
          <b-table-column
              label="COD ບາດ"
              field="amount_thb"
              sortable
              v-slot="props"
              cell-class="has-text-right"
          >
            {{ props.row.cod_thb | formatnumber }}
          </b-table-column>
          <b-table-column
              label="ຮັບເງີນ ກີບ"
              field="cod_payment_lak"
              sortable
              v-slot="props"
              cell-class="has-text-right"
          >
            {{ props.row.cod_payment_lak | formatnumber }}
          </b-table-column>
          <b-table-column
              label="ຮັບເງີນ ບາດ"
              field="cod_payment_thb"
              sortable
              v-slot="props"
              cell-class="has-text-right"
          >
            {{ props.row.cod_payment_thb | formatnumber }}
          </b-table-column>
          <b-table-column
              label="ໝາຍເຫດ"
              field="status"
              sortable
              v-slot="props"
              cell-class="has-text-left is-size-7">
           {{ props.row.is_receiver_pay? "ຄ່າຝາກເກັບປາຍທາງ":""}}
          </b-table-column>

          <empty-table v-if="models.length == 0" :isLoading="isLoading" />
        </b-table>
      </card-component>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { get } from "vuex-pathify";
import CardComponent from "@/components/CardComponent";
import EmptyTable from "../components/EmptyTable.vue";
import moment from "moment";
import store from "@/store";
import _ from "lodash";
import XLSX from "xlsx";
export default {
  name: "ReportCODShipments",
  components: { CardComponent, EmptyTable },
  computed: {
    user: get("user"),
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      title: "ຄ່າຝາກເຄື່ອງ COD",
      models: [],
      isShowModal: false,
      isLoading: false,
      fromdate: new Date(),
      todate: new Date(),
      branchId: 0,
      branchs: [],
      hasCancel: 0,
    };
  },
  mounted() {
    this.getBranch();
    this.getUserBranchId();
    this.get();
  },
  watch:{
    models:function() {
      this.isLoading = false;
       this.hasCancel=  ( _.countBy(this.models, r=>{return r.cod_flag===1;})).true;
    },
  },
  methods: {
    get() {
      this.isLoading=true;
      const fdate = moment(this.fromdate).format("YYYY-MM-DD");
      const tdate = moment(this.todate).format("YYYY-MM-DD");
      const query = {branchid: this.branchId};
      axios.get(`report/codshipments/${fdate}/${tdate}`,{params:  query}).then((r) => {
        const list=[];
        if(r && r.length>0){
          r.forEach(i=>{
            const lak=i.cod.filter(e=>e.ccy==='LAK');
            const thb=i.cod.filter(e=>e.ccy==='THB');
            const usd=i.cod.filter(e=>e.ccy==='USD');
            i["codlak"]= lak.length>0?lak[0].amount:0;
            i["codthb"]= thb.length>0?thb[0].amount:0;
            i["codusd"]= usd.length>0?usd[0].amount:0;
            list.push(i);
          });
        }
        this.models = list;
      });
    },
    getUserBranchId(){
      this.branchId=store.getters.getUserBranchId;
    },
    getBranch(){
      this.branchs=store.getters.getBranch;
    },
    view(item) {
      console.log(item);
      this.$router.push({ path: "/report/shipment/" + item.id });
    },
    selectFromDate(e) {
      console.log(e);
    },
    selectToDate(e) {
      console.log(e);
    },
    getUserBranchInfo() {
      return store.getters.getUserIdBranchInfo;
    },
    exportExcel() {
      if (this.models.length<1 ) return;
      //Create workBook
      const workBook = XLSX.utils.book_new();
      /* worksheet  data */
      const header =[

        "ວັນທີ່ສ້າງລາຍການ",
        "ລະຫັດເຄື່ອງ",
        "ຊື່ຜູ້ສົ່ງ",
        "ເບີໂທຜູ້ສົ່ງ",
        "ຊື່ຜູ້ຮັບ",
        "ສາຂາຕົ້ນທາງ",
        "ສາຂາປາຍທາງ",
        "ວັນທີ່ຈ່າຍເຄື່ອງ",
        "ຈຳນວນເງີນຄ່າຝາກ",
        "ຈຳນວນເງີນຄ່າເຄື່ອງເປັນກີບ",
        "ຈຳນວນເງີນຄ່າເຄື່ອງເປັນບາດ",
        "ຈຳນວນເງີນຄ່າເຄື່ອງເປັນໂດ່ລ່າ",
        "ໝາຍເຫດ"
      ];

      const ws_data_sheet = [];
      ws_data_sheet.push(header);
      for (const item of this.models) {
        ws_data_sheet.push([
          moment(item.created_date).format("YYYY-MM-DD HH:mm:ss"),
          item.code
          ,item.sendername
          ,item.sendertel
          ,item.receivername
          ,item.branch_branchToshipment_from_branch_id.name
          ,item.branch_branchToshipment_to_branch_id.name
          ,moment(item.delivery_date).format("YYYY-MM-DD HH:mm:ss")
          ,item.amount
          ,item.codlak
          ,item.codthb
          ,item.codusd
          , item.is_receiver_pay?"ຄ່າຝາກເກັບປາຍທາງ":""
        ]);
      }
      /* create worksheet */
      const ws = XLSX.utils.aoa_to_sheet(ws_data_sheet);
      /* Add the worksheet to the workbook */
      XLSX.utils.book_append_sheet(workBook, ws, "ຄ່າສົ່ງເຄື່ງ");

      /* Write workbook to file */
      const _date = moment(this.selectedDate).format("YYYY_MM_DD_HHmmss");
      const _ub = this.getUserBranchInfo();
      XLSX.writeFile(workBook, `COD_DETAIL_${_ub}_FILE_${_date}.xlsx`);
    },

  },
};
</script>
<style type="text/css">
.is-canceled {
  text-decoration: line-through !important;
}
</style>
